<template>
  <div>
    <div class="nav clearfix">
      <div class="titlebox">
        <span v-if="goback" @click="returnPage()" class="back el-icon-arrow-left">{{ back }}</span>
        <span v-if="goback" class="shu">|</span>
        <h1 class="notice">{{ title }}</h1>
      </div>
      <el-breadcrumb separator="/" class="beforetitle">
        <el-breadcrumb-item :to="{ path: '/index' }">
          {{
          index
          }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="workBench" :to="{ path: '/work_bench' }">
          {{
          work_bench
          }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="titleOnes">{{ titleOne }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="titleTwos">{{ titleTwo }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="titleThrees">
          {{
          titleThree
          }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ beforeTitle }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "back",
    "title",
    "index",
    "beforeTitle",
    "work_bench",
    "titleOne",
    "titleTwo",
    "titleThree",
  ],
  data () {
    return {
      goback: false,
      workBench: false,
      titleOnes: false,
      titleTwos: false,
      titleThrees: false,
    };
  },
  created () {
    if (this.back) {
      this.goback = true;
    }
    if (this.titleOne) {
      this.titleOnes = true;
    }
    if (this.titleTwo) {
      this.titleTwos = true;
    }
    if (this.titleThree) {
      this.titleThrees = true;
    }
    if (this.work_bench) {
      this.workBench = true;
    }
  },
  methods: {
    returnPage () {
      if (
        this.$route.path == "/notice/noticeMore" ||
        this.$route.path == "/memo/memoMore"
      ) {
        this.$router.push("/work_bench");
      } else {
        this.$router.back(-1);
      }
    },
  },
};
</script>

<style scoped>
.shu {
  font-size: 20px;
  color: #000;
  margin: 0 5px;
}
.nav {
  display: flex;
}
.titlebox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back {
  font-size: 20px;
  color: #000;
  display: inline-block;
  line-height: 21px;
}
.beforetitle {
  position: absolute;
  right: 20px;
}
.back:hover {
  font-weight: bold;
  cursor: pointer;
}
</style>
