<template>
  <div class="notfount">
    <img src="../assets/images/notfount.png" alt="" />
    <span>抱歉，您要访问的页面不见了！</span>
    <el-row>
      <el-button class="but" type="primary" @click="$router.push('/index')"
        >返回首页</el-button
      >
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.notfount {
  width: 100%;
  height: 100%;
}
.notfount img {
  width: 20%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.notfount span {
  position: fixed;
  top: 63%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
}
.but {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
