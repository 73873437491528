//按需引入vant组件（移动端组件）
import Vue from 'vue'
import 'vant/lib/index.css';
import { // 引入要使用的组件
    Icon, //icon
    Button, //按钮
    Search,
    NavBar, //导航栏
    List, //列表
    Popup, //弹出层
    Field, //Field输入框
    ActionSheet, //动作面板
    Picker, //选择器
    Sticky, //粘性（吸顶）
    Overlay, //遮罩层
} from 'vant'
Vue.use(Icon);
Vue.use(Button);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(List);
Vue.use(Popup);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Sticky);
Vue.use(Overlay);