import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import pdf from "../components/pdf.vue";
import Vuex from "vuex";
import NotFound from "../components/404";

Vue.use(Vuex);
Vue.use(VueRouter);
//获取原型对象上的push函数

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/",
    name: "Login",
    component: () =>
        import("../views/Login.vue"),
    meta: {
        requireAuth: false,
    },
},
{
    path: "/pdf",
    name: "pdf",
    component: () =>
        import("../components/pdf.vue"),
    meta: {
        requireAuth: true,
    },
},
{
    path: "/project/share_file_list",
    name: "share",
    component: () =>
        import("../views/project/share_file_list"),
    meta: {
        requireAuth: true,
        title: "项目列表-分享",
        GuidePath: true,
        JumpPath: "/project/index",
    },
},

{
    path: "/quote_file",
    // name: "miniData",
    component: () =>
        import("../views/quoteFile/index.vue"),
    meta: {
        requireAuth: true,
        title: "引用文件配置表",
    },
},
{
    path: "/quote_file/add_quote",
    // name: "miniData",
    component: () =>
        import("../views/quoteFile/addQuote.vue"),
    meta: {
        requireAuth: true,
        title: "新增引用文件配置表",
    },
},
{
    path: "/home",
    // name: "Home",
    component: Home,
    children: [{
        path: "/index",
        // name: "Index",
        component: () =>
            import("../views/index.vue"),
        meta: {
            requireAuth: true,
            title: "系统首页",
        },
    },
    {
        path: "/work_bench",
        // name: "Index",
        component: () =>
            import("../views/work_bench.vue"),
        meta: {
            requireAuth: true,
            title: "工作台",
        },
    },
    //   产品结果
    {
        path: "/work_bench/peoduct_end",
        // name: "Index",
        component: () =>
            import("../views/ends/productEnd.vue"),
        meta: {
            requireAuth: true,
            title: "产品结果",
        },
    },
    //   企业结果
    {
        path: "/work_bench/bus_end",
        // name: "Index",
        component: () =>
            import("../views/ends/busEnd.vue"),
        meta: {
            requireAuth: true,
            title: "企业结果",
        },
    },
    {
        path: "/notice/detail_notice",
        component: () =>
            import("../views/notice/noticeDetail.vue"),
        meta: {
            requireAuth: true,
            title: "通知详情",
        },
    },
    {
        path: "/notice/noticeMore",
        component: () =>
            import("../views/notice/noticeMore.vue"),
        meta: {
            requireAuth: true,
            title: "通知更多",
        },
    },
    {
        path: "/notice/add",
        component: () =>
            import("../views/notice/noticeAdd.vue"),
        meta: {
            requireAuth: true,
            title: "发布通知",
        },
    },
    {
        path: "/memo/memoDetail",
        component: () =>
            import("../views/memo/memoDetail.vue"),
        meta: {
            requireAuth: true,
            title: "备忘录详情",
        },
    },
    {
        path: "/memo/memoMore",
        component: () =>
            import("../views/memo/memoMore.vue"),
        meta: {
            requireAuth: true,
            title: "备忘录更多",
        },
    },
    {
        path: "/memo/memoAdd",
        component: () =>
            import("../views/memo/memoAdd.vue"),
        meta: {
            requireAuth: true,
            title: "新增备忘录",
        },
    },
    {
        path: "/mission/index",
        // name: "Index",
        component: () =>
            import("../views/mission/index.vue"),
        meta: {
            requireAuth: true,
            title: "我的任务",
            keepAlive: true,
        },
    },
    {
        path: "/mission/start",
        // name: "Index",
        component: () =>
            import("../views/mission/start.vue"),
        meta: {
            requireAuth: true,
            title: "我的任务-开始",
            GuidePath: true,
            JumpPath: "/mission/index",
        },
    },
    {
        path: "/user/index",
        // name: "index",
        component: () =>
            import("../views/Users/User.vue"),
        meta: {
            requireAuth: true,
            title: "用户管理",
            keepAlive: true,
        },
    },
    {
        path: "/users/add",
        // name: "edit",
        component: () =>
            import("../views/Users/Useradd.vue"),
        meta: {
            requireAuth: true,
            title: "用户管理新增",
            GuidePath: true,
            JumpPath: "/user/index",
            keepAlive: false,
        },
    },
    {
        path: "/authority/index",
        // name: "index",
        component: () =>
            import("../views/Authority/index.vue"),
        meta: {
            requireAuth: true,
            title: "角色管理",
            keepAlive: true,
        },
    },
    // 部门管理部门管理部门管理部门管理部门管理部门管理
    {
        path: "/organization/index",
        // name: "index",
        component: () =>
            import("../views/Authority/classAdmin.vue"),
        meta: {
            requireAuth: true,
            title: "部门管理",
        },
    },
    {
        path: "/organization/class_admin/add_edit",
        // name: "index",
        component: () =>
            import("../views/Authority/classAdd.vue"),
        meta: {
            requireAuth: true,
            title: "部门管理-新增/编辑",
            GuidePath: true,
            JumpPath: "/organization/index",
        },
    },
    {
        path: "/authority/operation",
        // name: "Operation",
        component: () =>
            import("../views/Authority/Operation.vue"),
        meta: {
            requireAuth: true,
            title: "权限管理-操作权限",
            GuidePath: true,
            JumpPath: "/authority/index",
        },
    },
    {
        path: "/authority/data",
        // name: "data",
        component: () =>
            import("../views/Authority/Data.vue"),
        meta: {
            requireAuth: true,
            title: "权限管理-数据权限",
            GuidePath: true,
            JumpPath: "/authority/index",
        },
    },
    {
        path: "/report/index",
        // name: "index",
        component: () =>
            import("../views/Report/index.vue"),
        meta: {
            requireAuth: true,
            title: "汇报管理",
            keepAlive: true,
        },
    },
    {
        path: "/report/add",
        // name: "add",
        component: () =>
            import("../views/Report/add.vue"),
        meta: {
            requireAuth: true,
            title: "汇报管理添加页面",
            GuidePath: true,
            JumpPath: "/report/index",
            keepAlive: false,
        },
    },
    {
        path: "/report/check",
        // name: "check",
        component: () =>
            import("../views/Report/check.vue"),
        meta: {
            requireAuth: true,
            title: "汇报管理查看页面",
            GuidePath: true,
            JumpPath: "/report/index",
            keepAlive: false,
        },
    },
    {
        path: "/data/index",
        // name: "index",
        component: () =>
            import("../views/DataCenter/index.vue"),
        meta: {
            requireAuth: true,
            title: "数据中心",
            keepAlive: true,
        },
    },
    {
        path: "/data/edit",
        // name: "edit",
        component: () =>
            import("../views/DataCenter/edit.vue"),
        meta: {
            requireAuth: true,
            title: "数据中心编辑",
            GuidePath: true,
            JumpPath: "/data/index",
            keepAlive: false,
        },
    },
    {
        path: "/data/check",
        // name: "edit",
        component: () =>
            import("../views/DataCenter/check.vue"),
        meta: {
            requireAuth: true,
            title: "数据中心查看",
            GuidePath: true,
            JumpPath: "/data/index",
            keepAlive: false,
        },
    },
    {
        path: "/data/import",
        // name: "Import",
        component: () =>
            import("../views/DataCenter/Import.vue"),
        meta: {
            requireAuth: true,
            title: "数据导入",
        },
    },
    {
        path: "/match/index",
        // name: "Match",
        component: () =>
            import("../views/Match/index.vue"),
        meta: {
            requireAuth: true,
            title: "智能匹配",
        },
    },

    {
        path: "/match/matchCo",
        // name: "matchCo",
        component: () =>
            import("../views/Match/matchCo.vue"),
        meta: {
            requireAuth: true,
            title: "企业匹配",
            GuidePath: true,
            JumpPath: "/match/index",
        },
    },
    {
        path: "/match/matchCoInfo",
        // name: "matchCoInfo",
        component: () =>
            import("../views/Match/matchCoInfo.vue"),
        meta: {
            requireAuth: true,
            title: "企业匹配结果",
            GuidePath: true,
            JumpPath: "/match/index",
        },
    },
    {
        path: "/match/matchBus",
        // name: "matchCoInfo",
        component: () =>
            import("../views/Match/matchBus.vue"),
        meta: {
            requireAuth: true,
            title: "匹配企业",
            GuidePath: true,
            JumpPath: "/match/index",
        },
    },
    {
        path: "/match/matchPo",
        // name: "matchPo",
        component: () =>
            import("../views/Match/matchPo.vue"),
        meta: {
            requireAuth: true,
            title: "政策匹配",
            GuidePath: true,
            JumpPath: "/match/index",
        },
    },
    {
        path: "/match/matchPoInfo",
        // name: "matchPoInfo",
        component: () =>
            import("../views/Match/matchPoInfo.vue"),
        meta: {
            requireAuth: true,
            title: "政策匹配结果",
            GuidePath: true,
            JumpPath: "/match/index",
        },
    },
    {
        path: "/match/policy_result",
        // name: "policyResult",
        component: () =>
            import("../views/Match/policyResult.vue"),
        meta: {
            requireAuth: true,
            title: "智能匹配政策结果",
        },
    },
    {
        path: "/match/company_result",
        // name: "companyResult",
        component: () =>
            import("../views/Match/companyResult.vue"),
        meta: {
            requireAuth: true,
            title: "智能匹配企业结果",
        },
    },
    {
        path: "/product/index",
        // name: "ProductList",
        component: () =>
            import("../views/product/index.vue"),
        meta: {
            requireAuth: true,
            title: "产品列表",
            keepAlive: true,
        },
    },
    {
        path: "/product/edit",
        // name: "guide",
        component: () =>
            import("../views/product/edit.vue"),
        meta: {
            requireAuth: true,
            title: "产品列表编辑",
            GuidePath: true,
            JumpPath: "/product/index",
            keepAlive: false,
        },
    },
    {
        path: "/product/guide",
        // name: "guide",
        component: () =>
            import("../views/product/guide.vue"),
        meta: {
            requireAuth: true,
            title: "产品列表指南",
            GuidePath: true,
            JumpPath: "/product/index",
            keepAlive: false,
        },
    },
    {
        path: "/product/Policyrules",
        // name: "Policyrules",
        component: () =>
            import("../views/product/Policyrules.vue"),
        meta: {
            requireAuth: true,
            title: "产品列表政策规则",
            GuidePath: true,
            JumpPath: "/product/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/index",
        // name: "index",
        component: () =>
            import("../views/patent/index.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权",
            keepAlive: true,
        },
    },
    {
        path: "/patent/add",
        // name: "add",
        component: () =>
            import("../views/patent/add.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-新增",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/upload",
        // name: "upload",
        component: () =>
            import("../views/patent/upload.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-上传",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/checkcost",
        name: "checkcost",
        component: () =>
            import("../views/patent/checkcost.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-费用",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/annex",
        name: "annex",
        component: () =>
            import("../views/patent/annex.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-专利列表-附件",
            GuidePath: true,
            JumpPath: "/patent/project",
            keepAlive: false,
        },
    },
    {
        path: "/soft/annex",
        name: "softAnnex",
        component: () =>
            import("../views/patent/softAnnex.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-软著列表-附件",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },

    {
        path: "/soft/download",
        // name: "dataExport",
        component: () =>
            import("../views/patent/softDownload.vue"),
        meta: {
            requireAuth: true,
            JumpPath: "/patent/index",
            title: "软著列表-批量下载",
        },
    },
    {
        path: "/soft/upload",
        // name: "dataExport",
        component: () =>
            import("../views/patent/softUpload.vue"),
        meta: {
            requireAuth: true,
            GuidePath: true,
            JumpPath: "/patent/index",
            title: "软著列表-附件快传",
        },
    },
    {
        path: "/patent/rzadd",
        name: "RZadd",
        component: () =>
            import("../views/patent/RZadd.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-软著列表-新增",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/check/add",
        component: () =>
            import("../components/patentCheck/checkAdd.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-查新检测-新增/编辑",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/check/upload",
        component: () =>
            import("../components/patentCheck/checkUpload.vue"),
        meta: {
            requireAuth: true,
            title: "科创模块-知识产权-查新检测-附件",
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/fee",
        // name: "add",
        component: () =>
            import("../views/patent/patentCost.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-专利费用",
            keepAlive: true,
        },
    },
    {
        path: "/patent/annual_fee",
        // name: "add",
        component: () =>
            import("../views/patent/patentFee.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-专利年费",
            keepAlive: true,
        },
    },
    {
        path: "/patent/middle",
        // name: "add",
        component: () =>
            import("../views/patent/patentCenter.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-中间文件",
            keepAlive: true,
        },
    },
    {
        path: "/patent/panel",
        // name: "add",
        component: () =>
            import("../views/patent/patentData.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-数据仪表",
            keepAlive: true,
        },
    },
    {
        path: "/patent/mission",
        // name: "add",
        component: () =>
            import("../views/patent/patentTask.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-专利任务",
            keepAlive: true,
        },
    },
    {
        path: "/patent/task_edit",
        // name: "add",
        component: () =>
            import("../views/patent/patentTaskEdit.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-专利任务-编辑",
            keepAlive: true,
        },
    },
    {
        path: "/patent/file",
        // name: "add",
        component: () =>
            import("../views/patent/annexManage.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-附件管理",
            keepAlive: true,
        },
    },
    {
        path: "/patent/annex_quick",
        // name: "add",
        component: () =>
            import("../views/patent/annexQuick.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-附件快传",
            keepAlive: true,
        },
    },
    {
        path: "/patent/project",
        // name: "add",
        component: () =>
            import("../views/patent/patentManage.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-案卷管理",
            keepAlive: true,
        },
    },
    {
        path: "/patent/manage_import",
        // name: "add",
        component: () =>
            import("../views/patent/manageImport.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-案卷管理-案卷导入",
            keepAlive: true,
            GuidePath: true,
            JumpPath: "/patent/project",
        },
    },
    {
        path: "/patent/patent_edit",
        // name: "add",
        component: () =>
            import("../views/patent/patentEdit.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-案卷管理-编辑/新增",
            // keepAlive: true,
            GuidePath: true,
            JumpPath: "/patent/project",
        },
    },
    {
        path: "/patent/project",
        // name: "add",
        component: () =>
            import("../views/patent/project.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利",
            keepAlive: true,
        },
    },
    {
        path: "/patent/zladd",
        // name: "add",
        component: () =>
            import("../views/patent/ZLadd.vue"),
        meta: {
            requireAuth: true,
            title: "工程专利-新增",
            GuidePath: true,
            JumpPath: "/patent/project",
            keepAlive: false,
        },
    },
    {
        path: "/patent/quick",
        // name: "add",
        component: () =>
            import("../views/patent/quick.vue"),
        meta: {
            requireAuth: true,
            title: "专利工程-快捷上传",
            GuidePath: true,
            JumpPath: "/patent/project",
            keepAlive: false,
        },
    },
    {
        path: "/patent/quick/file_look_upload",
        component: () =>
            import("../views/patent/fileLookUp.vue"),
        meta: {
            requireAuth: true,
            title: "专利工程-快捷上传-附件识别上传",
            GuidePath: true,
            JumpPath: "/patent/project",
            keepAlive: false,
        },
    },
    {
        path: "/patent/quickUpload",
        // name: "add",
        component: () =>
            import("../views/patent/quickUpload.vue"),
        meta: {
            title: "知识产权-快捷上传",
            requireAuth: true,
            GuidePath: true,
            JumpPath: "/patent/index",
            keepAlive: false,
        },
    },
    {
        path: "/patent/project_detail",
        component: () =>
            import("../views/patent/projectDetail.vue"),
        meta: {
            requireAuth: true,
            title: "专利工程-详情",
            GuidePath: true,
            JumpPath: "/patent/project",
            keepAlive: false,
        },
    },
    {
        path: "/policy/index",
        name: "政策资讯",
        component: () =>
            import("../views/PoInfo/index.vue"),
        meta: {
            keepAlive: true,
            requireAuth: true,
        },
    },
    {
        path: "/PoInfo/detail",
        name: "政策资讯详情",
        component: () =>
            import("../views/PoInfo/detail.vue"),
        meta: {
            requireAuth: true,
            GuidePath: true,
            JumpPath: "/website/policy",
            keepAlive: false,
        },
    },
    {
        path: "/PoInfo/edit",
        name: "edit",
        component: () =>
            import("../views/PoInfo/edit.vue"),
        meta: {
            requireAuth: true,
            title: "政策资讯新增/编辑",
            GuidePath: true,
            JumpPath: "/website/policy",
            keepAlive: false,
        },
    },
    {
        path: "/rd/index",
        // name: "index",
        component: () =>
            import("../views/rd/index.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目",
            keepAlive: true,
        },
    },
    {
        path: "/rd/add",
        name: "add",
        component: () =>
            import("../views/rd/add.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目新增",
            GuidePath: true,
            JumpPath: "/rd/index",
            keepAlive: false,
        },
    },
    {
        path: "/rd/situation",
        name: "XMSituation",
        component: () =>
            import("../views/rd/XMSituation.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目-项目情况表",
            GuidePath: true,
            JumpPath: "/rd/index",
            keepAlive: false,
        },
    },
    {
        path: "/rd/decision",
        name: "LXresolution",
        component: () =>
            import("../views/rd/LXresolution.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目-立项决议表",
            GuidePath: true,
            JumpPath: "/rd/index",
            keepAlive: false,
        },
    },
    {
        path: "/rd/Plan",
        name: "Plan",
        component: () =>
            import("../views/rd/Plan.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目-研发项目计划书",
            GuidePath: true,
            JumpPath: "/rd/index",
            keepAlive: false,
        },
    },
    {
        path: "/rd/report",
        name: "report",
        component: () =>
            import("../views/rd/report.vue"),
        meta: {
            requireAuth: true,
            title: "研发项目-研发验收报告",
            GuidePath: true,
            JumpPath: "/rd/index",
            keepAlive: false,
        },
    },
    {
        path: "/ps/index",
        // name: "add",
        component: () =>
            import("../views/ps/index.vue"),
        meta: {
            requireAuth: true,
            title: "高新产品",
            keepAlive: true,
        },
    },
    {
        path: "/ps/info",
        name: "info",
        component: () =>
            import("../views/ps/info.vue"),
        meta: {
            requireAuth: true,
            title: "高新产品-产品信息",
            GuidePath: true,
            JumpPath: "/ps/index",
            keepAlive: false,
        },
    },
    {
        path: "/ps/technical",
        name: "Technical",
        component: () =>
            import("../views/ps/Technical.vue"),
        meta: {
            requireAuth: true,
            title: "高新产品-技术指标",
            GuidePath: true,
            JumpPath: "/ps/index",
            keepAlive: false,
        },
    },
    {
        path: "/ps/ps",
        name: "ps",
        component: () =>
            import("../views/ps/PS.vue"),
        meta: {
            requireAuth: true,
            title: "高新产品-技术指标",
            GuidePath: true,
            JumpPath: "/ps/index",
            keepAlive: false,
        },
    },

    {
        path: "/finance/index",
        // name: "add",
        component: () =>
            import("../views/finance/index.vue"),
        meta: {
            requireAuth: true,
            title: "财务列表",
            keepAlive: true,
        },
    },
    {
        path: "/finance/info",
        // name: "info",
        component: () =>
            import("../views/finance/info.vue"),
        meta: {
            requireAuth: true,
            title: "财务列表-开票信息",
            GuidePath: true,
            JumpPath: "/finance/index",
            keepAlive: false,
        },
    },
    {
        path: "/finance/edit",
        // name: "edit",
        component: () =>
            import("../views/finance/edit.vue"),
        meta: {
            requireAuth: true,
            title: "财务列表-编辑",
            GuidePath: true,
            JumpPath: "/finance/index",
            keepAlive: false,
        },
    },
    {
        path: "/finance/examine",
        name: "Review",
        component: () =>
            import("../views/finance/Review.vue"),
        meta: {
            requireAuth: true,
            title: "财务审核",
            keepAlive: true,
        },
    },
    {
        path: "/finance/detail",
        component: () =>
            import("../views/finance/detail.vue"),
        meta: {
            requireAuth: true,
            title: "财务审核-详情",
            GuidePath: true,
            JumpPath: "/finance/examine",
            keepAlive: false,
        },
    },
    {
        path: "/project/index",
        // name: "index",
        component: () =>
            import("../views/project/index"),
        meta: {
            requireAuth: true,
            title: "项目列表",
            keepAlive: true,
        },
    },
    {
        path: "/project/info",
        // name: "info",
        component: () =>
            import("../views/project/info"),
        meta: {
            requireAuth: true,
            title: "项目列表-基本信息",
            GuidePath: true,
            JumpPath: "/project/index",
            keepAlive: false,
        },
    },
    {
        path: "/project/Process",
        name: "Process",
        component: () =>
            import("../views/project/Process"),
        meta: {
            requireAuth: true,
            title: "项目列表-基本信息",
            GuidePath: true,
            JumpPath: "/project/index",
            keepAlive: false,
        },
    },
    {
        path: "/project/Process/info_clone",
        name: "Process",
        component: () =>
            import("../views/project/infoClone.vue"),
        meta: {
            requireAuth: true,
            title: "项目列表-基本信息-完善信息",
            GuidePath: true,
            JumpPath: "/project/index",
            keepAlive: false,
        },
    },

    {
        path: "/contract/index",
        // name: "index",
        component: () =>
            import("../views/contract/index"),
        meta: {
            requireAuth: true,
            title: "合同列表",
            keepAlive: true,
        },
    },
    {
        path: "/contract/add",
        // name: "add",
        component: () =>
            import("../views/contract/add"),
        meta: {
            requireAuth: true,
            title: "合同列表-新增/编辑",
            GuidePath: true,
            JumpPath: "/contract/index",
            keepAlive: false,
        },
    },
    {
        path: "/contract/detail",
        // name: "detail",
        component: () =>
            import("../views/contract/detail"),
        meta: {
            requireAuth: true,
            title: "合同列表-详情",
            GuidePath: true,
            JumpPath: "/contract/index",
            keepAlive: false,
        },
    },
    {
        path: "/contract/annex",
        // name: "detail",
        component: () =>
            import("../views/contract/annex"),
        meta: {
            requireAuth: true,
            title: "合同列表-附件",
            GuidePath: true,
            JumpPath: "/contract/index",
            keepAlive: false,
        },
    },
    {
        path: "/contract/examine",
        // name: "index",
        component: () =>
            import("../views/contract/examine"),
        meta: {
            requireAuth: true,
            title: "合同审核",
            keepAlive: true,
        },
    },
    {
        path: "/examine/annex",
        // name: "detail",
        component: () =>
            import("../views/contract/annex"),
        meta: {
            requireAuth: true,
            title: "合同审核-附件",
            GuidePath: true,
            JumpPath: "/contract/examine",
            keepAlive: false,
        },
    },
    {
        path: "/contract/project",
        // name: "index",
        component: () =>
            import("../views/contract/project"),
        meta: {
            requireAuth: true,
            title: "合作项目",
            keepAlive: true,
        },
    },
    {
        path: "/contract/invoic",
        name: "invoic",
        component: () =>
            import("../views/contract/invoic"),
        meta: {
            requireAuth: true,
            title: "合作项目-开票",
            GuidePath: true,
            JumpPath: "/contract/project",
            keepAlive: false,
        },
    },
    {
        path: "/contract/edit",
        // name: "edit",
        component: () =>
            import("../views/contract/projectEdit"),
        meta: {
            requireAuth: true,
            title: "合作项目-编辑",
            GuidePath: true,
            JumpPath: "/contract/project",
            keepAlive: false,
        },
    },
    {
        path: "/contract/con_detail",
        // name: "edit",
        component: () =>
            import("../views/contract/conDetail.vue"),
        meta: {
            requireAuth: true,
            title: "合作项目-详情",
            GuidePath: true,
            JumpPath: "/contract/project",
            keepAlive: false,
        },
    },
    {
        path: "/partner/index",
        // name: "index",
        component: () =>
            import("../views/Partner/index"),
        meta: {
            requireAuth: true,
            title: "合作客户",
            keepAlive: true,
        },
    },
    {
        path: "/Partner/detail",
        // name: "detail",
        component: () =>
            import("../views/Partner/detail"),
        meta: {
            requireAuth: true,
            title: "合作客户-编辑",
            GuidePath: true,
            JumpPath: "/partner/index",
            keepAlive: false,
        },
    },
    {
        path: "/Partner/company_detail",
        // name: "detail",
        component: () =>
            import("../views/Partner/companyDetail.vue"),
        meta: {
            requireAuth: true,
            title: "合作客户-详情",
            GuidePath: true,
            JumpPath: "/partner/index",
            keepAlive: false,
        },
    },
    {
        path: "/statistics/index",
        // name: "index",
        component: () =>
            import("../views/customTongji/index.vue"),
        meta: {
            requireAuth: true,
            title: "客户仪表",
            // keepAlive: true,
        },
    },
    {
        path: "/potential/index",
        // name: "index",
        component: () =>
            import("../views/potential/index"),
        meta: {
            requireAuth: true,
            title: "意向客户",
            keepAlive: true,
        },
    },
    {
        path: "/potential/info",
        // name: "info",
        component: () =>
            import("../views/potential/info"),
        meta: {
            requireAuth: true,
            title: "意向客户-客户信息",
            GuidePath: true,
            JumpPath: "/potential/index",
            keepAlive: false,
        },
    },
    {
        path: "/potential/detail",
        // name: "info",
        component: () =>
            import("../views/potential/detail.vue"),
        meta: {
            requireAuth: true,
            title: "意向客户-客户详情",
            GuidePath: true,
            JumpPath: "/potential/index",
            keepAlive: false,
        },
    },
    {
        path: "/setting/index",
        name: "Settings",
        component: () =>
            import("../views/Settings.vue"),
        meta: {
            requireAuth: true,
            title: "个人设置",
        },
    },

    {
        path: "/data/export",
        name: "dataExport",
        component: () =>
            import("../views/DataCenter/dataExport.vue"),
        meta: {
            requireAuth: true,
            JumpPath: "/data/export",
            title: "数据导出",
        },
    },
    {
        path: "/data/data_export/go_export",
        name: "goExport",
        component: () =>
            import("../views/DataCenter/goexport.vue"),
        meta: {
            JumpPath: "/data/data_export",
            requireAuth: true,
            title: "数据导出-去导出",
        },
    },
    {
        path: "/custom/public_pool",
        name: "miniData",
        component: () =>
            import("../views/wxGqbwen/index.vue"),
        meta: {
            requireAuth: true,
            title: "公共池",
        },
    },
    {
        path: "/website/banner",
        // name: "miniData",
        component: () =>
            import("../views/website/index.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-首页维护",
        },
    },
    {
        path: "/website/policy",
        name: "官网维护-科技政策",
        component: () =>
            import("../views/website/policy.vue"),
        meta: {
            requireAuth: true,
            keepAlive: true,
        },
    },
    {
        path: "/website/school",
        // name: "miniData",
        component: () =>
            import("../views/website/cloudAcademy.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-云学院",
            keepAlive: true,
        },
    },
    {
        path: "/website/school/course_add",
        // name: "miniData",
        component: () =>
            import("../views/website/courseAdd.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-云学院-申报教程新增/编辑",
        },
    },
    {
        path: "/website/school/img_add",
        // name: "miniData",
        component: () =>
            import("../views/website/imgAdd.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-云学院-图文解读新增/编辑",
        },
    },
    {
        path: "/website/school/model_add",
        // name: "miniData",
        component: () =>
            import("../views/website/temAdd.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-云学院-模板下载新增/编辑",
        },
    },
    {
        path: "/website/school/play_add",
        // name: "miniData",
        component: () =>
            import("../views/website/playAdd.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-云学院-平台活动新增/编辑",
        },
    },
    {
        path: "/website/service",
        // name: "miniData",
        component: () =>
            import("../views/website/give.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-众创服务",
            keepAlive: true,
        },
    },
    {
        path: "/website/service/service_add",
        // name: "miniData",
        component: () =>
            import("../views/website/giveAdd.vue"),
        meta: {
            requireAuth: true,
            title: "官网维护-众创服务-新增/编辑",
        },
    },
    ],
},
{
    path: "/wpsfile",
    // name: "miniData",
    component: () =>
        import("../components/wps.vue"),
    meta: {
        requireAuth: true,
        title: "WPS",
    },
},
{
    path: "/phone/work_bench",
    name: "work_bench",
    component: () =>
        import("../phone/workBench/work_bench.vue"),
    meta: {
        requireAuth: true,
        title: "工作台",
    },
    children: []
},
{
    path: "/phone/work_bench/report_list",
    name: "report_list",
    component: () =>
        import("../phone/report/report_list.vue"),
    meta: {
        requireAuth: true,
        title: "汇报列表",
    },
},
{
    path: "/phone/report/add",
    name: "report_add",
    component: () =>
        import("../phone/report/report_add.vue"),
    meta: {
        requireAuth: true,
        title: "写汇报",
    },
},
{
    path: "/phone/report/detail",
    name: "report_detail",
    component: () =>
        import("../phone/report/report_detail.vue"),
    meta: {
        requireAuth: true,
        title: "写汇报",
    },
},
{
    path: "/NotFount",
    name: "NotFount",
    component: () =>
        import("../components/404"),
},
{
    path: "*",
    redirect: "/NotFount",
},

];

const router = new VueRouter({
    mode: "history",
    routes,
});
// 路由守卫核心代码
router.beforeEach((to, from, next) => {
    if (to.path === '/project/share_file_list') return next()
    if (to.path === "/" && !window.localStorage.getItem("token")) return next();
    if (to.path === "/" && window.localStorage.getItem("token"))
        return next("/index");
    if (!window.localStorage.getItem("token")) return next("/");
    next();
});

export default router;