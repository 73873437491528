import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import { Message } from "element-ui";
// 头部公共组件全局引入
import header from "./components/Header/hearer";
Vue.use(header);


// 初次加载全屏动画组件全局引入
import loading from "./components/loading/loading";
Vue.use(loading);

// import { Table } from "ant-design-vue";
// Vue.use(Table);

// 引入字体文件
import "@/assets/fonts/font.css";
// Vue.prototype.$api = axios;
// 配置请求根路径
// axios.defaults.baseURL = "http://www.gaoqibang.com:8081";


//引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;


//导入样式
import "./theme/index.css";
import "./assets/css/global.css";


//导入字体文件
import "./assets/fonts/iconfont.css";


//导入pdf功能
import pdf from "vue-pdf";


//时间格式化
import moment from "moment";

// 引入vant组件
import '../src/vant-import.js'


Vue.filter("fmtdate", (v) => {
    return moment.unix(v).format("YYYY-MM-DD");
});


import MetaInfo from "vue-meta-info";
Vue.use(MetaInfo);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // console.log('haha ,请求被我拦截到了把', config)
        // config.headers.aa = 'bb'
        // config.baseURL = 'http://localhost:8888/api/private/v1/
        config.headers.Authorization = "bearer " + localStorage.getItem("token");
        // console.log('config.headers.Authorization',config.headers.Authorization)
        // store.commit("handleLoading",true)
        return config;
    },
    function (error) {
        // console.log('main.js ---request:', error)
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    function (res) {
        // 对响应数据做点什么
        // console.log('拦截到了响应', res)
        // 直接返回res.data，对应的就是服务器返回的数据
        // store.commit("handleLoading",false)
        // console.log(res, "拦截器");
        return res.data;
        // if (res.data.errcode == 1002) {
        //   localStorage.removeItem("token");
        //   window.location.href = "/";
        // } else if (res.data.token) {
        //   localStorage.setItem("token");
        // }
        // this.axios.get("/api/member/view_self", {
        // 	params: {
        // 		token: res.data.data
        // 	}
        // }).then((res) => {
        // 	console.log(res)
        // })
    },
    function (error) {
        // console.log("main.js ---:response", error);
        // 对响应错误做点什么
        if (error.response.status === 401) {
            // 401 说明 token 验证失败
            // 可以直接跳转到登录页面，重新登录获取 token
            localStorage.removeItem("token");
            Message({ message: "身份验证过期，请重新登录", type: "error" });
            router.push("/");
        } else if (error.response.status == 500) {
            Message({ message: "服务器内部错误", type: "error" });
        } else if (error.response.status == 404) {
            Message({ message: "访问路径有误", type: "error" });
        }
        return Promise.reject(error);
    }
);