<template>
  <el-container style="height: 100%">
    <el-header>
      <div class="logo">
        <img src="../assets/images/logo.png" />
      </div>
      <div class="topName">
        <div class="topInfo">
          <el-dropdown>
            <img :src="userSite + url" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p @click="JumpSet">个人设置</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="logout">退出账号</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <p>{{ true_name }}</p>
      </div>
      <div class="topTime">{{ current_date }} {{ week }}</div>
    </el-header>
    <el-container>
      <el-aside width="250px">
        <el-menu :default-active="leftMenu" router unique-opened>
          <div v-for="(item, id) in menu" :key="id">
            <template v-if="item.children.length">
              <el-submenu :index="'s' + item.id" :key="item.id">
                <el-badge v-if="menu.id == 19" :value="3" class="item"></el-badge>
                <template #title>
                  <i :class="item.icon"></i>
                  <span>{{ item.title }}</span>
                  <el-badge
                    v-if="item.id == 19"
                    :value="finance_count + receipt_count"
                    class="item"
                    :max="99"
                  ></el-badge>
                  <el-badge v-if="item.id == 24" :value="contract_count" class="item" :max="99"></el-badge>
                </template>
                <template v-for="subItem in item.children">
                  <el-submenu
                    v-if="subItem.children.length"
                    :index="subItem.href"
                    :key="subItem.id"
                  >
                    <template #title>{{ subItem.title }}</template>

                    <el-menu-item
                      v-for="(
                                                threeItem, i
                                            ) in subItem.children"
                      :key="i"
                      :index="threeItem.href"
                    >
                      {{ threeItem.title }}
                      <!-- <el-badge :value="2" class="item"></el-badge> -->
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item
                    v-if="subItem.children.length == 0"
                    :index="subItem.href"
                    :key="subItem.id"
                  >
                    {{ subItem.title }}
                    <el-badge v-if="subItem.title == '财务列表'" :value="receipt_count" class="item"></el-badge>
                    <el-badge v-if="subItem.title == '财务审核'" :value="finance_count" class="item"></el-badge>
                    <el-badge
                      v-if="subItem.title == '合同审核'"
                      :value="contract_count"
                      class="itemlist"
                    ></el-badge>
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
            <template v-if="item.children.length == 0">
              <el-menu-item :index="item.href" :key="item.id">
                <i :class="item.icon?item.icon:'work_bench'"></i>
                <template #title>
                  {{ item.title }}
                  <el-badge v-if="item.title == '我的任务'" :value="mission_count" class="index"></el-badge>
                </template>
              </el-menu-item>
            </template>
          </div>
        </el-menu>
      </el-aside>
      <el-container style="background: #f2f3f8">
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer>版权归高企邦©2019苏ICP备19067318号</el-footer>
      </el-container>
    </el-container>
    <!-- 页面加载全屏动画 -->
    <loading v-if="show"></loading>
  </el-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import site from '../components/global.vue'
export default {
  data () {
    return {
      menu: [],
      show: true,
      userSite: site.userSite,
      true_name: '',
      url: '',
      current_date: '',
      week: '',
      contract_count: '',
      finance_count: '',
      mission_count: '',
      receipt_count: '',
    }
  },
  computed: {
    leftMenu () {
      return this.$route.meta.GuidePath
        ? this.$route.meta.JumpPath
        : this.$route.path
    },
  },
  created () {
    this.token = localStorage.getItem('token')
    this.axios
      .get('/api/role/menu', { params: { token: this.token } })
      .then((res) => {
        this.menu = res.data
        this.show = false
      })
    this.axios
      .get('/api/member/view_self', {
        params: {
          token: this.token,
        },
      })
      .then((res) => {
        this.true_name = res.data.true_name
        this.url = res.data.avatar
        this.current_date = res.data.current_date
        this.week = res.data.week
        this.show = false;
        // console.log(res, "个人信息");
      })
  },
  methods: {
    logout () {
      this.axios.post('/api/member/logout').then((res) => {
        // console.log(res, '退出登录')/
        localStorage.clear()
        setTimeout(() => {
          this.$router.push('/')
          window.location.reload(true);
        }, 500)
      })
    },
    JumpSet () {
      this.$router.push('/setting/index')
    },
  },
  mounted () {
    this.axios.get('/api/mission/mission_count').then((res) => {
      this.contract_count = res.data.contract_count
      this.finance_count = res.data.finance_count
      this.mission_count = res.data.mission_count
      this.receipt_count = res.data.receipt_count
    })
  },
}
</script>

<style scoped="scoped">
.el-header {
  background: linear-gradient(90deg, #1131ff 0%, #005dff 100%);
  position: relative;
  color: #fff;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  background-color: #fff;
}

.el-main {
  position: absolute;
  left: 250px;
  right: 0;
  top: 60px;
  bottom: 55px;
  overflow-y: scroll;
}

.el-footer {
  position: absolute;
  left: 250px;
  right: 0;
  bottom: 0;
}

::-webkit-scrollbar {
  width: 0;
}

.logo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: left;
}

.logo img {
  width: 55%;
  height: 55%;
}

.topTime {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: right;
}

.topInfo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: right;
  margin-left: 20px;
}

.topName {
  height: 60px;
  float: right;
  margin-left: 20px;
}

.topName img {
  position: relative;
  float: right;
  top: 22px;
  right: 0;
  transform: translateY(-50%);
  margin-left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.topName p {
  position: relative;
  float: right;
  top: 26%;
  transform: translateY(-50%);
}

.el-dropdown-menu__item p {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;
}

.item {
  margin-left: 10px;
  margin-bottom: 2px;
}
.itemlist {
  margin-left: 8px;
}
.index {
  margin-left: 5px;
}
</style>
