import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { Loading } from "element-ui";
Vue.prototype.$Loading = Loading.service;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list: [],
    role: [],
    conlist: [],
    mydatalist: [],
    userinfo: "",
    reportdata: [],
    company: [],
    product: [],
    policy: [],
    contract: [],
    examine: [],
    project: [],
    patent: [],
    ps: [],
    rd: [],
    soft: [],
    finance: [],
    review: [],
    mission: [],
    // menu: [],
    xmlist: [],
    patproject: [],
    quick: [],
    // 存储备忘录数据列表
    memolist: [],
    // 公告通知详情
    noticeDetail: [],
    // 备忘录详情
    memodetail: [],
    // 科创关联
    relation: [],
    relationRD: [],
    relationIP: [],
    // 存储公告通知列表
    noticeList: [],
    // 存储工作台模块列表
    workList: [],
  },
  mutations: {
    getuserinfo(state, payload) {
      state.userinfo = payload;
    },
    initUserList(state, list) {
      state.list = list;
    },
    initRoleList(state, role) {
      state.role = role;
    },
    initConList(state, conlist) {
      state.conlist = conlist;
    },
    initMyList(state, mydatalist) {
      state.mydatalist = mydatalist;
    },
    initReportData(state, reportdata) {
      state.reportdata = reportdata;
    },
    initCompany(state, company) {
      state.company = company;
    },
    initProductList(state, product) {
      state.product = product;
    },
    initPolicyList(state, policy) {
      state.policy = policy;
    },
    initContractList(state, contract) {
      state.contract = contract;
    },
    initExamineList(state, examine) {
      state.examine = examine;
    },
    initProjectList(state, project) {
      state.project = project;
    },
    initPatentList(state, patent) {
      state.patent = patent;
    },
    initPSList(state, ps) {
      state.ps = ps;
    },
    initRDList(state, rd) {
      state.rd = rd;
    },
    initSoftList(state, soft) {
      state.soft = soft;
    },
    initFinanceList(state, finance) {
      state.finance = finance;
    },
    initReviewList(state, review) {
      state.review = review;
    },
    initMissionList(state, mission) {
      state.mission = mission;
    },
    // initMenuList(state, menu) {
    //   state.menu = menu;
    // },
    initXMList(state, xmlist) {
      state.xmlist = xmlist;
    },
    initpatproList(state, patproject) {
      state.patproject = patproject;
    },
    initquickList(state, quick) {
      state.quick = quick;
    },
    // 存储备忘录列表
    initmemolist(state, memo) {
      state.memolist = memo;
    },
    // 存储备忘录详情
    initmemodetail(state, memodetail) {
      state.memodetail = memodetail;
    },
    // 存储公告通知详情
    initNoticeDetail(state, noticeDetail) {
      state.noticeDetail = noticeDetail;
    },
    // 科创关联列表
    initrelation(state, relation) {
      state.relation = relation;
    },
    initrelationRD(state, relationRD) {
      state.relationRD = relationRD;
    },
    initrelationIP(state, relationIP) {
      state.relationIP = relationIP;
    },
    // 存储公告通知
    initNoticeList(state, noticeList) {
      state.noticeList = noticeList;
    },
    // 存储工作台模块
    initWorkList(state, workList) {
      state.workList = workList;
    },
  },
  actions: {
    //用户管理列表
    getUserList(context, params) {
      axios
        .get("/api/member/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initUserList", data);
        });
    },
    //权限管理列表
    getRoleList(context, params) {
      axios
        .get("/api/role/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initRoleList", data);
        })
        .catch((err) => {
          console.log("getRoleList---err:", err);
        });
    },
    //数据中心公用列表
    getConList(context, params) {
      axios
        .get("/api/data/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initConList", data);
        })
        .catch((err) => {
          console.log("getConList---err:", err);
        });
    },
    //数据中心个人列表
    getMydataList(context, params) {
      axios
        .get("/api/data/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initMyList", data);
        })
        .catch((err) => {
          console.log("getMydataList---err:", err);
        });
    },
    //汇报管理列表
    getReportData(context, params) {
      axios
        .get("/api/report/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initReportData", data);
        })
        .catch((err) => {
          console.log("getReportData---err:", err);
        });
    },
    //客户管理列表
    getCompany(context, params) {
      axios
        .get("/api/company/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initCompany", data);
        })
        .catch((err) => {
          console.log("getCompany---err:", err);
        });
    },
    //产品列表
    getProductList(context, params) {
      axios
        .get("/api/policy/product_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initProductList", data);
        })
        .catch((err) => {
          console.log("getProductList---err:", err);
        });
    },
    //政策资讯
    getPolicyList(context, params) {
      axios
        .get("/api/policy/policy_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initPolicyList", data);
        })
        .catch((err) => {
          console.log("getPolicyList---err:", err);
        });
    },
    //合同列表
    getContractList(context, params) {
      axios
        .get("/api/contract/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initContractList", data);
        })
        .catch((err) => {
          console.log("getContractList---err:", err);
        });
    },
    //合同审核列表
    getExamineList(context, params) {
      axios
        .get("/api/contract/audit_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initExamineList", data);
        })
        .catch((err) => {
          console.log("getExamineList---err:", err);
        });
    },
    //合作项目列表
    getProjectList(context, params) {
      axios
        .get("/api/contract/projcet_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initProjectList", data);
        })
        .catch((err) => {
          console.log("getProjectList---err:", err);
        });
    },
    //专利列表
    getPatentList(context, params) {
      axios
        .get("/api/patent/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initPatentList", data);
        })
        .catch((err) => {
          console.log("getPatentList---err:", err);
        });
    },
    //高新产品列表
    getPSList(context, params) {
      axios
        .get("/api/ps/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initPSList", data);
        })
        .catch((err) => {
          console.log("getPSList---err:", err);
        });
    },
    //研发项目RD列表
    getRDList(context, params) {
      axios
        .get("/api/rd/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initRDList", data);
        })
        .catch((err) => {
          console.log("getRDList---err:", err);
        });
    },
    //软著列表
    getSoftList(context, params) {
      axios
        .get("/api/soft/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initSoftList", data);
        })
        .catch((err) => {
          console.log("getSoftList---err:", err);
        });
    },
    //财务列表
    getFinanceList(context, params) {
      axios
        .get("/api/finance/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initFinanceList", data);
        })
        .catch((err) => {
          console.log("getFinanceList---err:", err);
        });
    },
    //财务审核列表
    getReviewList(context, params) {
      axios
        .get("/api/finance/audit_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initReviewList", data);
        })
        .catch((err) => {
          console.log("getReviewList---err:", err);
        });
    },
    //我的任务列表
    getMissionList(context, params) {
      axios
        .get("/api/mission/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initMissionList", data);
        })
        .catch((err) => {
          console.log("getMissionList---err:", err);
        });
    },
    //导航列表
    // getMenuList(context, params) {
    //   axios
    //     .get("/api/role/menu", {
    //       params,
    //     })
    //     .then(({ data }) => {
    //       context.commit("initMenuList", data);
    //     })
    //     .catch((err) => {
    //       console.log("getMenuList---err:", err);
    //     });
    // },
    //项目列表
    getXMList(context, params) {
      axios
        .get("/api/order/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initXMList", data);
        })
        .catch((err) => {
          console.log("getXMList---err:", err);
        });
    },
    //专利工程
    getpatproList(context, params) {
      axios
        .get("/api/patent/project_patent_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initpatproList", data);
        })
        .catch((err) => {
          console.log("getpatproList---err:", err);
        });
    },
    //快捷上传
    getquickList(context, params) {
      axios
        .get("/api/patent/quick_patent_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initquickList", data);
        })
        .catch((err) => {
          console.log("getquickList---err:", err);
        });
    },
    //备忘录更多列表
    getmemolist(context, params) {
      axios
        .get("/api/memorandum/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initmemolist", data);
        })
        .catch((err) => {
          console.log("getmemolist---err:", err);
        });
    },
    //备忘录详情页面
    getmemodetail(context, params) {
      axios
        .get("/api/memorandum/view", {
          params,
        })
        .then(({ data }) => {
          context.commit("initmemodetail", data);
        })
        .catch((err) => {
          console.log("getmemodetail---err:", err);
        });
    },
    //公告通知详情页面
    getNoticeDetail(context, params) {
      axios
        .get("/api/notice/view", {
          params,
        })
        .then(({ data }) => {
          context.commit("initNoticeDetail", data);
        })
        .catch((err) => {
          console.log("getNoticeDetail---err:", err);
        });
    },
    //项目列表科创关联
    getrelation(context, params) {
      axios
        .get("/api/order/order_related_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initrelation", data);
        })
        .catch((err) => {
          console.log("getrelation---err:", err);
        });
      console.log(params);
    },
    getrelationRD(context, params) {
      axios
        .get("/api/order/order_related_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initrelationRD", data);
        })
        .catch((err) => {
          console.log("getrelationRD---err:", err);
        });
      // console.log(params);
    },
    getrelationIP(context, params) {
      axios
        .get("/api/order/order_related_list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initrelationIP", data);
        })
        .catch((err) => {
          console.log("getrelationIP---err:", err);
        });
      // console.log(params);
    },
    // 公告通知列表
    getNoticeList(context, params) {
      axios
        .get("/api/notice/list", {
          params,
        })
        .then(({ data }) => {
          context.commit("initNoticeList", data);
        })
        .catch((err) => {
          console.log("getNoticeList---err:", err);
        });
      // console.log(params);
    },
    // 工作台模块列表
    getWorkList(context, params) {
      axios
        .get("/api/member/workbench", {
          params,
        })
        .then(({ data }) => {
          context.commit("initWorkList", data);
        })
        .catch((err) => {
          console.log("getWorkList---err:", err);
        });
    },
  },
  modules: {},
});
