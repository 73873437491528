<template>
	<div class="pdf">
		<div class="main">
			<pdf ref="pdf" :src="pdfUrl" :page="pageNum" :rotate="pageRotate" @password="password" @progress="loadedRatio = $event"
			 @page-loaded="pageLoaded($event)" @num-pages="pageTotalNum=$event" @error="pdfError($event)" @link-clicked="page = $event">
			</pdf>
		</div>
		<div class="footers">
			<ul>
				<li>
					<button class="btn-def btn-pre" @click.stop="clock">顺时针</button>
				</li>
				<li>
					<button class="btn-def btn-next" @click.stop="counterClock">逆时针</button>
				</li>
				<li><button class="btn-def btn-pre" @click.stop="prePage">上一页</button></li>
				<li><button class="btn-def btn-next" @click.stop="nextPage">下一页</button></li>
				<li>
					<div>{{pageNum}}/{{pageTotalNum}}</div>
				</li>
				<li><button :class="{select:idx==0}" @touchstart="idx=0" @touchend="idx=-1" @click="scaleD">放大 </button></li>
				<li><button :class="{select:idx==1}" @touchstart="idx=1" @touchend="idx=-1" @click="scaleX">缩小</button></li>
				<li><button @click="fileDownload(pdfUrl,'pdf文件')">下载</button></li>
				<li>
					<div>进度：{{loadedRatio}}</div>
				</li>
				<li>
					<div>页面加载成功: {{curPageNum}}</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf';
	export default {
		components: {
			pdf
		},
		data() {
			return {
				pdfUrl: "http://tindex.gaoqitong.cn/uploads/file/20210605/1622877779769090.pdf",
				pageNum: 1,
				pageTotalNum: 1,
				pageRotate: 0,
				loadedRatio: 0, // 加载进度
				curPageNum: 0,
				scale: 100, //放大系数
				idx: -1,
			}
		},
		methods: {
			//下载PDF
			fileDownload(data, fileName) {
				let blob = new Blob([data], {
					//type类型后端返回来的数据中会有，根据自己实际进行修改
					type: "application/pdf;charset-UTF-8"
				});
				let filename = fileName || "报表.xls";
				if (typeof window.navigator.msSaveBlob !== "undefined") {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					var blobURL = window.URL.createObjectURL(blob);
					// 创建隐藏<a>标签进行下载
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = blobURL;
					tempLink.setAttribute("download", filename);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					window.URL.revokeObjectURL(blobURL);
				}
			},
			//放大
			scaleD() {
				this.scale += 5;
				this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
			},

			//缩小
			scaleX() {
				if (this.scale == 100) {
					return;
				}
				this.scale += -5;
				this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
			},
			prePage() {
				var p = this.pageNum
				p = p > 1 ? p - 1 : this.pageTotalNum
				this.pageNum = p
			},
			nextPage() {
				var p = this.pageNum
				p = p < this.pageTotalNum ? p + 1 : 1
				this.pageNum = p
			},
			clock() {
				this.pageRotate += 90
			},
			counterClock() {
				this.pageRotate -= 90
			},
			password(updatePassword, reason) {
				updatePassword(prompt('password is "123456"'))
				console.log('...reason...')
				console.log(reason)
				console.log('...reason...')
			},
			pageLoaded(e) {
				this.curPageNum = e
			},
			pdfError(error) {
				console.error(error)
			},
			pdfPrintAll() {
				this.$refs.pdf.print()
			},
			pdfPrint() {
				this.$refs.pdf.print(100, [1, 2])
			},
		}
	}
</script>

<style scoped="scoped">
	.main {
		overflow: hidden;
		width: 800px;
		margin: 0 auto;
	}

	.footers {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		z-index: 100;
		color: #333;
		border-top: 1px solid #f0f0f0;
		line-height: 10px;
		height: 40px;
		background: #fff;
	}

	.footers ul li {
		text-align: center;
		flex: 1;
		padding: 5px;
		cursor: pointer;
		display: inline-block;
	}

	.ul {
		list-style: none;
	}

	.more-p {
		border-right: 1px solid #f0f0f0;
		margin-right: 40px;
		cursor: pointer;
	}

	.small-p {
		margin-right: 40px;
		cursor: pointer;
	}

	.up-p {
		margin-right: 40px;
		cursor: pointer;
	}

	.down-p {
		border-radius: 0 none;
		cursor: pointer;
		margin-right: 50px;
	}
</style>
