<!--<script>
const userSite = "http://106.15.138.240:8082";
export default {
  userSite, //接口地址测试
};
</script>-->

<!---->
<script>
const userSite = "http://tagent.gaoqitong.cn:8082";
export default {
  userSite, //接口地址正式
};
</script>

<!--
<script>
const userSite = "http://www.gaoqibang.com:8081";
export default {
  userSite, //接口地址本地
};
</script>-->