<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
  </div>
</template>
<script>
import DevicePixelRatio from './utils/devicePixelRatio.js'
export default {
  name: "App",
  provide () {
    return {
      reload: this.reload,
    };
  },
  data () {
    return {
      isRouterAlive: true,
      screenWidth: null,//屏幕尺寸
    };
  },
  created () {
    // console.log(this.$route, 'router')
    new DevicePixelRatio().init()
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        // if (val < 450 && !oldVal) {
        //   this.$router.push('/phone/work_bench')
        // }
        // if (val > 450 && !oldVal) {
        //   this.$router.push('/')
        // }
      },
      immediate: true,
      // deep: true
    },
  },
  mounted () {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }

    if (this._isMobile()) {
      this.$router.replace('/phone/work_bench');
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // reload() {
    //   this.isRouterAlive = false;
    //   this.$nextTick(() => {
    //     this.isRouterAlive = true;
    //   });
    // },
  },
};
</script>
<style>
div/deep/.el-table__row:hover .cell .el-button {
  background: #fff !important;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
div/deep/.cell {
  padding: 2px 0;
}
div/deep/.el-form-item__label {
  color: #404040;
}
::v-deep .el-input {
  width: 224px !important;
}
.content {
  min-height: 100%;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px; /*滚动条的背景区域的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.3);
  background-color: #eeeeee; /*滚动条的背景颜色*/
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3);
  background-color: rgb(145, 143, 143); /*滚动条的背景颜色*/
}
</style>
